<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-img
      src="@/assets/sld3.png"
      contain
    />
    <v-parallax
      dark
      height="1000"
      src="/img/background.jpg"
    >
      <v-layout
        column
        align-center
        justify-center
      >
        <div class="headline white--text mb-3 text-xs-center">
          BERITA BAIK! TIADA modal diperlukan untuk menjadi peniaga XOX sekarang!
        </div>
        <v-btn
          color="success"
          variant="text"
          @click="sldDialog = true"
        >
          Daftar sebagai pengedar
        </v-btn>
      </v-layout>
      <v-layout
        row
        wrap
        align-center
        justify-center
      >
        <v-flex xs8>
          <div
            ref="xoxid"
            class="headline white--text my-2 text-xs-center"
          >
            Manfaat & Keistimewaan
          </div>
        </v-flex>
        <v-flex xs10>
          <v-card
            color="green darken-2"
            class="white--text"
          >
            <v-layout>
              <v-flex xs3>
                <v-img
                  src="@/assets/Asset2.png"
                  height="100px"
                  contain
                />
              </v-flex>
              <v-flex xs7>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">
                      Fleksibiliti
                    </div>
                    <div>Tidak melibatkan aktiviti rangkaian</div>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs10>
          <v-card
            color="green darken-2"
            class="white--text"
          >
            <v-layout>
              <v-flex xs3>
                <v-img
                  src="@/assets/Asset3.png"
                  height="100px"
                  contain
                />
              </v-flex>
              <v-flex xs7>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">
                      Peroleh Keuntungan
                    </div>
                    <div>Pendapatan bulanan berdasarkan prestasi jualan peribadi</div>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs10>
          <v-card
            color="green darken-2"
            class="white--text"
          >
            <v-layout>
              <v-flex xs3>
                <v-img
                  src="@/assets/Asset1.png"
                  height="100px"
                  contain
                />
              </v-flex>
              <v-flex xs7>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">
                      Manfaat Tambahan
                    </div>
                    <div>Terbuka PERCUMA secara automatik kepada pengguna Black baharu dan sedia ada</div>
                    <div>Tidak termasuk B10 & Supp Line OneFamily</div>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs10>
          <v-card
            color="green darken-2"
            class="white--text"
          >
            <v-layout>
              <v-flex xs3>
                <v-img
                  src="@/assets/Asset4.png"
                  height="100px"
                  contain
                />
              </v-flex>
              <v-flex xs7>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">
                      Membantu peniaga
                    </div>
                    <div>Mempunyai khidmat sokongan dan sistem sendiri untuk membantu pengedar</div>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-card>
          <v-dialog
            v-model="sldDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">Daftar Sebagai Pengedar</span>
              </v-card-title>

              <v-alert
                :value="true"
                :type="executeAlertType"
                v-html="executeAlertMessage"
              />
              <v-card-text
                v-show="executeAlertType !== 'success'"
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  readonly
                />
                <v-text-field
                  v-model="msisdn"
                  label="Msisdn"
                  readonly
                />
                <v-text-field
                  v-model="black"
                  label="Plan"
                  readonly
                />
                <v-text-field
                  v-model="mykad"
                  label="MyKad"
                  readonly
                />
                <v-text-field
                  v-model="street"
                  label="Street"
                  readonly
                />
                <v-text-field
                  v-model="city"
                  label="City"
                  readonly
                />
                <v-text-field
                  v-model="stateName"
                  label="State"
                  readonly
                />
                <v-text-field
                  v-model="email"
                  label="Email"
                  readonly
                />

                <template>
                  <v-container fluid>
                    <v-checkbox
                      v-model="checkbox"
                      :true-value="1"
                      :false-value="0"
                      :rules="[v => !!v || 'Anda mesti bersetuju untuk meneruskan!']"
                    >
                      <template #label>
                        <div>
                          Saya bersetuju dengan
                          <v-tooltip location="bottom">
                            <template #activator="{ props }">
                              <v-btn
                                v-bind="props"
                                color="primary"
                                flat
                                @click="term = true"
                              >
                                Terma dan syarat
                              </v-btn>
                            </template>
                            Buka terma dan syarat
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-container>
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  flat
                  @click="sldDialog = false"
                >
                  {{ executeAlertType === 'info' ? 'Kembali' : 'Tutup' }}
                </v-btn>
                <v-btn
                  v-show="executeAlertType !== 'success'"
                  color="blue darken-1"
                  flat
                  @click="submit()"
                >
                  Daftar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="term"
            persistent
            max-width="350px"
          >
            <v-card>
              <v-card-title class="headline">
                Terma and Syarat
              </v-card-title>
              <v-card-text>Saya mengaku bahawa semua maklumat di atas adalah lengkap dan benar. Sekiranya kenyataan yang diberikan tidak benar atau tidak lengkap, ONE XOX Sdn Bhd  berhak untuk membatalkan pendaftaran ini.</v-card-text>
              <tr>
                <v-card-text>Dengan meneruskan pendaftaran ini, saya bersetuju dengan semua terma & syarat selaku pengedar Single Level Dealership (SLD) dan segala aktiviti saya selaku pengedar ONE XOX Sdn Bhd adalah tertakluk kepada garis panduan yang ditetapkan oleh syarikat dan Suruhanjaya Komunikasi & Multimedia (SKMM).</v-card-text>
              </tr>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  flat
                  @click="term = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar
            v-model="snackbar"
            :color="alertColor"
            :timeout="3000"
            top
          >
            {{ alertText }}
            <v-btn
              dark
              icon
              floating
              small
              flat
              @click="snackbar = false"
            >
              <v-icon>clear</v-icon>
            </v-btn>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-parallax>
  </v-container>
</template>

<!-- <style>
 .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width : 300%;
}
.text--primary {
  justify-content: center;
  align-items: center;
}
.card-actions {
  position: absolute;
  bottom: 0px;
  right: 3px;
}
</style> -->

<script>

import { createGetParams, createPostParams } from '@/rest'

export default {
  data () {
    return {
      name: null,
      msisdn: null,
      mykad: null,
      black: null,
      street: null,
      stateName: null,
      city: null,
      email: null,
      term: false,
      checkbox: false,
      snackbar: false,
      menu: false,
      monthOf: null,
      // maxDate: '',
      loading: false,
      search: '',
      totalItems: 0,
      pagination: {},
      items: 2,
      panel: [],
      error: null,
      alertColor: 'success',
      dialog: false,
      sldDialog: false,
      show: false,
      alertText: '',
      executeAlertType: 'info',
      executeAlertMessage: null,
    }
  },
  mounted () {
    const params = createGetParams({
      profiling: true,
    })

    this.$rest.get('getProfile.php', params)
      .then(response => {
        this.name = response.data.name
        this.msisdn = response.data.msisdn
        this.mykad = response.data.id
        this.black = response.data.black
        this.street = response.data.street
        this.stateName = response.data.stateName
        this.city = response.data.city
        this.email = response.data.email
      })
  },
  watch: {
    sldDialog () {
      this.executeAlertType = 'info'
      this.executeAlertMessage = '<div>Tekan <strong>Daftar</strong> untuk menjadi pengedar ONEXOX</div>'
    },
  },
  methods: {
    close () {
      this.sldDialog = false
      location.reload()
    },
    async submit () {
      const params = createPostParams({
        action: 'appointDealer',
        payload: {
          sahabat: 'Y',
          type: 'SLD',
        },
      })
      if (this.checkbox === 1) {
        try {
          await this.$rest.post('postAction.php', params)
          this.$nextTick(() => {
            this.executeAlertType = 'success'
            this.executeAlertMessage = 'Anda telah berjaya menjadi pengedar ONEXOX'
          })
        } catch (e) {
          this.$nextTick(() => {
            this.executeAlertType = 'error'
            this.executeAlertMessage = e.message + ': ' + e.response.data
          })
        } finally {
          this.loading = false
        }
      } else {
        this.executeAlertType = 'error'
        this.executeAlertMessage = 'Anda mesti bersetuju terma dan syarat untuk meneruskan!'
      }
    },
  },
}
</script>
